<template>
    <div class="privacy-policy-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Our privacy policy <i class="fas fa-lock"></i></h1>
                        <h4>This Policy shall be read, considered and interpreted with the Website Terms and Conditions and Cookie Policy.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="flex-row justify-content-center">
                        <ol>
                            <li class="section-container"><b>PRIVACY STATEMENT IN TERMS OF THE GDPR AND POPIA</b>
                                <ol>
                                    <li>The following data will be collected:
                                        <ol>
                                            <li>The Registered User's Name, Surname, Address, City, State, Zipcode, Country, Email address,  Phone number, Ethnicity, Gender, Relationship status, Medical scheme, Medical scheme plan,  Medical aid number, Identify number, Bloodtype, Drinker type, Smoker type, Height, Weight, Family history, Disease, Dependents, Next of kin, Practice number, Practice Bank account details for purposes of payments, Practitioner associations, Practitioner qualification, Practitioner institute and year of qualification, NPI number, IP address, non-personal browsing habits and click patterns.</li>
                                            <li>As and when necessary, the Company may electronically collect, store, disclose and/or use the personal information.</li>
                                            <li>Information collected from Registered Users is required to utilise the functionality of the Platform and shall be relayed to a Practitioner of the user's choice and such information shall not be used for any other purpose without the Registered User's prior consent.</li>
                                            <li>All information collected is kept strictly confidential, and all reasonable steps are taken to ensure that information is secured in storage for a period of 5 (five) years until ultimate destruction. It will not be shared with any third party without the prior written consent of the Registered User.</li>
                                            <li>The Company shall take all reasonable steps to protect the personal information of Registered Users and is committed to respecting the privacy of the Registered User's personal information. For the purpose of these T&C's, “personal information” shall be defined as detailed in the POPIA. As and when necessary, the Company may electronically collect, store, disclose and/or use the Registered User's personal information.</li>
                                            <li>The Company will ensure that all it's employees, third-party service providers, (including their employees and third-party service providers) having access to the Registered User's personal information are bound by appropriate and legally binding confidentiality obligations in relation to the Registered User's personal information and that such confidentiality meets any applicable law, regulation, legal process, or enforceable governmental request.</li>
                                            <li>The Registered User's personal data will be hosted and stored in countries which might not have the adequacy decision of the European Union; other third-party contractors may have access to the Registered User's data only for the purpose specified herein, and the access of such third parties is strictly controlled.</li>
                                            <li>Whenever the Company is sending data to countries that are not providing the same level of protection as the EU's GDPR, the Company will use appropriate safeguards to protect the Registered User's personal data, including but not limited to Standard Contractual Clauses for Processors.</li>
                                        </ol>
                                    </li>
                                    <li>The Company collects stores and uses the abovementioned information in order to:
                                        <ol>
                                            <li>Communicate requested information to Registered Users;</li>
                                            <li>Respond to queries, responses or complaints submitted by Registered Users;</li>
                                            <li>Process orders or applications for the Company Products and/or services;</li>
                                            <li>Create Products or services that may meet the future requirements of Registered Users;</li>
                                            <li>Provide Registered Users with access to restricted pages on the Platform; and</li>
                                            <li>Compile non-personal statistical information about browsing habits, click patterns and access to the Platform.</li>
                                        </ol>
                                    </li>
                                    <li>Personal information detailed above is collected and/or stored either electronically using “cookies” or is provided voluntarily with the Registered User's knowledge and consent. The Registered User can determine any use of cookies through your browser settings but note that turning off cookies may cause certain features of the online services or Platform to be unavailable to the Registered User.</li>
                                    <li>The Company may further collect non-personal information, for example, the Registered User's IP address, the date and time of their visits to the Platform, and browser history, to recognise the Registered User during any subsequent visits to the Platform and/or use of the online services. The Company may further use this non-personal information to develop future products and/or services to meet the Registered User's requirements and needs.</li>
                                    <li>The Company owns and retains all rights to the non-personal statistical information collected and compiled by the Company.</li>
                                    <li>The Company will not share the Registered User's personal information outside of the Company except in the following cases:
                                        <ol>
                                            <li>With prior consent;</li>
                                            <li>With its employees and/or third-party service providers who assist with the Platform;</li>
                                            <li>When processing of information is necessary for the performance of a contract with the Registered User;</li>
                                            <li>When the Company has a legal obligation to share the information;</li>
                                            <li>When the information is necessary in order to protect the vital interests of the Registered User or of another person;</li>
                                            <li>When the information is necessary for the performance of a task carried out in the public interest or the exercise of official authority vested;</li>
                                            <li>When the information is necessary for the purposes of the legitimate interests pursued by the Company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the Registered User which require protection of personal data.</li>
                                        </ol>
                                    </li>
                                    <li>It is not allowed (hereinafter “Prohibited Practices”) to:
                                        <ol>
                                            <li>Use the Platform in a manner which may cause damage to the Company, other Users or any third party;</li>
                                            <li>Undermine the security or integrity of any of the Company's computing systems or networks;</li>
                                            <li>Use the Platform in any way to impair functionality or interfere with other Users;</li>
                                            <li>Access the Platform or Website without permission;</li>
                                            <li>Make use of the Company's systems to commit fraud;</li>
                                            <li>Act in a manner that is disrespectful or abusive to the Company systems and staff;</li>
                                            <li>publish, upload, exchange or transmit Prohibited Content;</li>
                                            <li>publish, upload, exchange or transmit any content that the Registered User knows to be false or untrue, or has justifiable reasons to believe it to be false or untrue, and whose use may cause damage to the Company , other Users or third parties;</li>
                                            <li>for any Registered User misrepresenting him/her/itself for the purpose of deceiving the Company, the Users or third parties;</li>
                                            <li>publish, upload, exchange or transmit to the Company, other Users or third parties any unwanted notices or other content of commercial or malicious nature, without prior request or consent, mainly including notices and other content of the same or similar nature;</li>
                                            <li>purposeful publishing, uploading, exchange or transmission of any content containing computer viruses, worms, and programs that may obstruct or hinder the regular operation of the Platform, cause damage or destruction of any computer program, or any computer and other equipment owned by the Company other Users or third parties;</li>
                                            <li>collect, process or use personal data of the Registered Users or third parties in an unauthorised manner;</li>
                                            <li>engage in overt or covert advertising (verbal or graphic representation of Products, personal names, names of companies, names, trademarks (registered or unregistered trademarks and service marks), businesses etc.) in an unauthorised manner.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li class="section-container"><b>COLLECTION AND PROCESSING OF PERSONAL DATA BY OTHER USERS OR THIRD PARTIES</b>
                                <ol>
                                    <li>The Platform contains content and may contain Links to third-party Platform, through which other Registered Users or third parties may gain authorised or unauthorised access to the Registered User's personal data. These T&C's do not apply to the collection, processing or use of personal data that the Registered User has communicated to other Registered Users and/or third parties. It is in the Registered User's best interest to acquaint themselves with the rules of personal data protection, and the protection of privacy applied by other Registered Users and/or third parties.</li>
                                    <li>The User will select a Practitioner with whom they wish to consult at their own discretion.</li>
                                    <li>All relevant Personal Information collected from the User, which constitutes Name, Surname, Email address, Phone number, Gender, Relationship status, Medical scheme, Medical scheme plan, Medical aid number, Identify number, Bloodtype, Drinker type, Smoker type, Height, Weight, Family history, Disease, Dependents, Next of kin will be shared with their elected Practitioner.</li>
                                </ol>
                            </li>
                            <li class="section-container"><b>MODIFICATION AND DELETION OF PERSONAL DATA</b>
                                <ol>
                                    The Registered User is legally entitled to request modification or deletion of their personal data or deletion from the Registered User database at any time. Modification or deletion of data shall be effected on the basis of an appropriate notice addressed to The Company.
                                </ol>
                            </li>
                            <li class="section-container"><b>DISCLOSURES REQUIRED BY SECTION 43 OF THE ECTA</b>
                                <ol>Access to the services, content, software and downloads available from the Online Products may be classified as “electronic transactions” as defined in terms of ECTA and you therefore may have the rights detailed in ECTA. Accordingly, the following information is provided:
                                    <li>The full name and legal status of the Online Product owner: Apace Systems (PTY) LTD with registration number: 2021/748725/07.</li>
                                    <li>Physical Address: Century on Lake C5, Century City, Cape Town, 7441, South Africa</li>
                                    <li>Main business: Online wellness platform</li>
                                    <li>The Website address of the Website is: www.drremo.co.za</li>
                                    <li>The official e-mail address of the Website is: drremo@health263.systems</li>
                                    <li>Access to and use of the Website is provided free of charge and paid for depending on User application and election;</li>
                                    <li>Users may lodge complaints concerning the Platform: drremo@health263.systems</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "privacy-policy",
    metaInfo() {
        return {
            title: "DrRemo | Privacy Policy",
            meta: [
                {
                    name: "description",
                    content: "The privacy of our patients and practitioners are of the utmost importance. Our privacy policy outlines the steps we take to protect the users of our platform."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/privacy"
                }
            ]
        }
    }
}
</script>